import { Form, Input, message, Modal } from "antd";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { handleFetchApi } from "../../../configs/apiRequest";
import CustomLockIcon from "../../../components/Icons/CustomLockIcon";
import CustomSuccessIcon from "../../../components/Icons/CustomSuccessIcon";
import { AAUTH } from "../../../apis/auth";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";

function ResetPassword({ student, open, close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const activeLoading = (check) => {
        if (check) {
            message.loading({ content: t("updating_information"), key: "update" });
        } else {
            message.destroy("update"); // Stop the loading message
        }
    };

    const updatePassword = useMutation({
        mutationFn: ({ new_password }) => {
            activeLoading(true);
            return handleFetchApi(
                AAUTH.adminResetPassword({
                    data: {
                        user_id: student?.user_id,
                        new_password: new_password?.trim(),
                    },
                })
            );
        },
        onSuccess: async (response, values) => {
            activeLoading(false); // Stop loading
            Modal.success({
                title: "Mật khẩu đã được đổi",
                content: `Mật khẩu mới: ${values?.new_password?.trim()}`,
                icon: <CustomSuccessIcon />,
                centered: true,
                onOk: () => {
                    form.resetFields();
                    close();
                },
                onCancel: () => {
                    form.resetFields();
                    close();
                },
            });
        },
        onError: async (error) => {
            activeLoading(false); // Stop loading
            Modal.error({
                title: t("unsuccessful"),
                content: t("password_requirements"),
                icon: <CustomDangerIcon />,
                centered: true,
                onOk: () => {
                    form.resetFields();
                    close();
                },
                onCancel: () => {
                    form.resetFields();
                    close();
                },
            });
        },
    });

    return (
        <Modal
            open={open}
            width={500}
            title={t("change_password")}
            okText={t("save")}
            cancelText={t("cancel")}
            onCancel={() => {
                form.resetFields();
                close();
            }}
            okButtonProps={{ form: "updatePassword", key: "submit", htmlType: "submit" }}
        >
            <Form
                id="updatePassword"
                form={form}
                requiredMark={false}
                layout="vertical"
                name="update_password"
                onFinish={(values) => updatePassword.mutate(values)}
                autoComplete="off"
            >
                <Form.Item label={t("new_password")} name="new_password" rules={[{ required: true, message: t("pls_password") }]}>
                    <Input.Password size="large" prefix={<CustomLockIcon />} placeholder={t("plh_new_password")} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ResetPassword;
