import React, { Suspense, lazy, useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BookContext } from "../../stores/bookStore";
import { StoreContext } from "../../stores";
import { handleNextPage, handleNextSinglePage, handlePrevPage, handlePrevSinglePage } from "../../utils/book";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { Layout } from "antd";
import Loading from "../../components/Loading";
import ErrorPage from "../Error";
import { debounce } from "../../utils";
import { ABOOK } from "../../apis/book";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const PageView = lazy(() => import("./components/PageView"));
const CollapseBook = lazy(() => import("./components/CollapseBook"));

function Book() {
    const { t } = useTranslation();
    const { book_id } = useParams();
    const { pageView, setPageView, pages, tabletMode } = useContext(BookContext);
    const { setSelectedExercise } = useContext(StoreContext);
    const bookmarks = useMemo(() => JSON.parse(localStorage.getItem("bookmarks")) ?? {}, []);
    const index = bookmarks[book_id] || 0;

    const {
        data: book,
        isLoading,
        isError,
    } = useQuery(
        [book_id],
        async () => {
            const bookData = await handleFetchApi(ABOOK.getAllInfo({ bookId: book_id }));
            return { ...bookData?.data, id: book_id, book_id: book_id };
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const gotoBookmark = useCallback(() => {
        let leftPage, rightPage;
        if (index % 2 === 1) {
            leftPage = {
                key: pages[index - 1].page_id,
                index: Number(index),
            };
            rightPage = {
                key: pages[index].page_id,
                index: Number(index) + 1,
            };
        } else {
            leftPage = {
                key: pages[index - 2].page_id,
                index: index - 1,
            };
            rightPage = {
                key: pages[index - 1].page_id,
                index: Number(index),
            };
        }
        setPageView([leftPage, rightPage]);
    }, [index, pages, setPageView]);

    useEffect(() => {
        if (pages?.length && index) {
            gotoBookmark();
        }
    }, [pages, index, gotoBookmark]);
    
    const handleSwipe = useCallback(
        (isLeftSwipe) => {
            if (tabletMode) {
                if (isLeftSwipe) {
                    handleNextSinglePage(setSelectedExercise, setPageView, pageView, pages);
                } else {
                    handlePrevSinglePage(setSelectedExercise, setPageView, pageView, pages);
                }
            } else {
                if (isLeftSwipe) {
                    handleNextPage(setSelectedExercise, setPageView, pageView, pages);
                } else {
                    handlePrevPage(setSelectedExercise, setPageView, pageView, pages);
                }
            }
        },
        [tabletMode, setSelectedExercise, setPageView, pageView, pages]
    );

    const handleKeyDown = useCallback(
        (e) => {
            if (tabletMode) {
                if (e.key === "ArrowRight") {
                    handleNextSinglePage(setSelectedExercise, setPageView, pageView, pages);
                }
                if (e.key === "ArrowLeft") {
                    handlePrevSinglePage(setSelectedExercise, setPageView, pageView, pages);
                }
            } else {
                if (e.key === "ArrowRight") {
                    handleNextPage(setSelectedExercise, setPageView, pageView, pages);
                }
                if (e.key === "ArrowLeft") {
                    handlePrevPage(setSelectedExercise, setPageView, pageView, pages);
                }
            }
        },
        [tabletMode, setSelectedExercise, setPageView, pageView, pages]
    );

    const touchHandlers = useMemo(() => {
        let touchStart = 0;
        const minSwipeDistance = 50;

        return {
            onTouchStart: (e) => {
                touchStart = e.targetTouches[0].clientX;
            },
            onTouchEnd: (e) => {
                const touchEnd = e.changedTouches[0].clientX;
                const distance = touchStart - touchEnd;
                if (Math.abs(distance) > minSwipeDistance) {
                    handleSwipe(distance > 0);
                }
            },
        };
    }, [handleSwipe]);

    if (isError) return <ErrorPage />;

    return (
        <Suspense fallback={<Loading />}>
            <Layout
                // {...touchHandlers}
                onKeyDown={debounce(handleKeyDown, 300)}
                tabIndex={0}
                className="cb_book"
            >
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <CollapseBook bookTitle={book?.title} pages={book?.book_infos} />
                        <Layout>
                            <Header book={book} />
                            <PageView bookCover={book?.book_cover_url} />
                            <Footer />
                        </Layout>
                    </>
                )}
            </Layout>
        </Suspense>
    );
}

export default React.memo(Book);
