import axios from "axios";
import Cookies from "js-cookie";
import { returnLogin } from "../utils";

// Constants
const MINUTE_IN_MS = 60000;
const AUTHENTICATION_FAIL = new Set(["UR0015", "UR0014", "UR0013", "UR0012", "UR0001"]);
const AUTHENTICATION_EXPIRED_CODE = new Set(["UR0006", "UR0009", "UR0016"]);
const SUCCESS_STATUS = new Set(["S0000", "S0001", "S0002"]);
const API_BASE_URL = "https://api.cloudbook.vn/api/v1";
const STAGING_API_BASE_URL = "https://staging.api.cloudbook.vn/api/v1";
const REQUEST_LIMIT = 3;
const REQUEST_INTERVAL = 1000; // 1 second

// Config
const isDevMode = window.location.href.includes("localhost") || window.location.href.includes("staging");

const api = axios.create({
    baseURL: isDevMode ? STAGING_API_BASE_URL : API_BASE_URL,
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get("access_token");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
    (response) => {
        if (!SUCCESS_STATUS.has(response.data?.status)) {
            return Promise.reject(response.data);
        }
        return response;
    },
    async (error) => {
        const { config: originalRequest, response } = error;
        const { status } = response?.data;
        // if (AUTHENTICATION_FAIL.has(status)) {
        //     handleAuthenticationFailure();
        //     return Promise.reject(error);
        // }
        if (AUTHENTICATION_EXPIRED_CODE.has(status)) {
            try {
                const { data } = await api.post("/auth/token/refresh", {
                    refresh_token: Cookies.get("refresh_token"),
                });
                Cookies.set("access_token", data?.data?.access_token);
                Cookies.set("refresh_token", data?.data?.refresh_token);

                originalRequest.headers.Authorization = `Bearer ${data?.data?.access_token}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error("Refresh token failed", refreshError);
                handleAuthenticationFailure();
                return Promise.reject(refreshError);
            }
        }
        if (AUTHENTICATION_FAIL.has(status)) {
            handleAuthenticationFailure();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

// Shared authentication handler
const handleAuthenticationFailure = () => {
    alert("Phiên đăng nhập đã hết hạn, hoặc hệ thống đang bảo trì. Vui lòng đăng nhập lại.");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    returnLogin();
};

// Cache configuration helper
export const cacheQueryConfig = ({ time = 3 } = {}) => ({
    staleTime: time * MINUTE_IN_MS,
    cacheTime: time * MINUTE_IN_MS,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
});

// Response validation helper
export const validResponse = ({ response }) => SUCCESS_STATUS.has(response?.data?.status || response?.status);

const requestQueue = [];
let requestCount = 0;
let processingQueue = false;

const processQueue = async () => {
    if (processingQueue) return;
    processingQueue = true;

    while (requestQueue.length > 0) {
        if (requestCount < REQUEST_LIMIT) {
            const { resolve, reject, method, url, data } = requestQueue.shift();
            requestCount++;
            try {
                const response = await api({ method, url, data });
                resolve(response.data);
            } catch (error) {
                reject(error);
            } finally {
                requestCount--;
                await new Promise((res) => setTimeout(res, REQUEST_INTERVAL / REQUEST_LIMIT));
            }
        } else {
            await new Promise((res) => setTimeout(res, REQUEST_INTERVAL / REQUEST_LIMIT));
        }
    }

    processingQueue = false;
};

export const handleFetchApi = ({ method = "GET", url, data = null }) => {
    if (!url) throw new Error("URL parameter is required");

    return new Promise((resolve, reject) => {
        requestQueue.push({ resolve, reject, method, url, data });
        processQueue();
    });
};
