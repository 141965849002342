import { Method } from "./enums";
import { UserEnums } from "../configs/enums";

export class AEDUCATION {
    static getSchool() {
        return {
            method: Method.GET,
            url: "/school",
        };
    }

    static getCourse() {
        return {
            method: Method.GET,
            url: "/course",
        };
    }

    static getClass() {
        return {
            method: Method.GET,
            url: "/class",
        };
    }

    static addTeacherToCourse({ data }) {
        return {
            method: Method.POST,
            url: "/course/teacher",
            data,
        };
    }

    static addClassToTeacher({ courseId, teacherId, data }) {
        return {
            method: Method.POST,
            url: `/course/${courseId}/teacher/${teacherId}`,
            data,
        };
    }

    static getClassesByCourseTeacher({ me, teacherId }) {
        let url = `/course/teacher/${teacherId}`;
        if (me?.role === UserEnums.HOME_TEACHER) url = "/class/home-teacher";
        return {
            method: Method.GET,
            url: url,
        };
    }

    static getClassesBySchool({ schoolId }) {
        return {
            method: Method.GET,
            url: `/class/school/${schoolId}`,
        };
    }

    static getTeachersBySchool({ schoolId }) {
        return {
            method: Method.GET,
            url: `/school/teacher/${schoolId}`,
        };
    }

    static getScoreByClass({ classId, timeRange }) {
        return {
            method: Method.GET,
            url: `/score/class/${classId}?fromDate=${timeRange?.from}&toDate=${timeRange?.to}`,
        };
    }

    static getStudentResultSumary({ courseId, studentId }) {
        return {
            method: Method.GET,
            url: `/course/${courseId}/student-result-summary/${studentId}`,
        };
    }

    static getStudentsByClass({ classId }) {
        return {
            method: Method.GET,
            url: `/class/student/${classId}?size=99`,
        };
    }

    static transferStudents({ oldClassId, newClassId, studentIds }) {
        return {
            method: Method.POST,
            url: `/class/students/transfer`,
            data: {
                oldClassId,
                newClassId,
                studentIds,
            },
        };
    }
    static removeClass({course_teacher_id, class_id}) {
        return {
            method: Method.POST,
            url: `course/class/remove`,
            data: {course_teacher_id, class_id}
        }
    }
    static transferClass({oldTeacherId, newTeacherId, classId}) {
        return {
            method: Method.POST,
            url: `course/class/transfer`,
            data: {oldTeacherId, newTeacherId, classId}
        }
    }
}
