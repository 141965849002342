import { Table, Button, Col, Typography, Select, Modal, Input, notification, Divider } from "antd";
import { useQuery, useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AEDUCATION } from "../../../apis/education";

const CourseDetail = ({ course = {} }) => {
    const [classes, setClasses] = useState([]);
    const [teacherId, setTeacherId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const { data: classData, isLoading: isLoadingClasses } = useQuery(["classes"], () => handleFetchApi(AEDUCATION.getClass()), {
        enabled: !!course.id,
    });

    const addTeacherToCourse = useMutation(async (teacherId) => {
        const response = await handleFetchApi(
            AEDUCATION.addTeacherToCourse({
                data: {
                    course_id: course.id,
                    teacher_id: teacherId,
                },
            })
        );
        if (response?.status === "status") notification.success({ message: "Thành công!" });
        setIsModalVisible(false);
    });

    const columns = [
        {
            title: "Class Name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => `Lớp ${record?.grade} ${record?.name?.replace("Lớp ", "")}`,
        },
        {
            title: "Trường",
            dataIndex: ["school", "name"],
            key: "teacherName",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record, index) => (
                <div className="flex__start__center gap-sm">
                    <Button type="primary" className="btn-info" onClick={() => navigate(`/course/${course.id}/class/${record.id}`)}>
                        Xem
                    </Button>
                    <Button
                        type="primary"
                        onClick={async () => {
                            const res = await handleFetchApi(AEDUCATION.transferClass({}));
                            if (res?.status === "S0000") notification.success({ message: "Chuyển thành công!" });
                        }}
                    >
                        Chuyển
                    </Button>
                    <Button
                        type="link"
                        danger
                        onClick={async () => {
                            const course_teacher_id = classes?.filter(cls => cls?.course?.id === course?.id)[0]
                            const res = await handleFetchApi(
                                AEDUCATION.removeClass({ course_teacher_id: course_teacher_id?.course_teacher_id, class_id: record.id })
                            );
                            if (res?.status === "S0000") notification.success({ message: "Xóa thành công!" });
                        }}
                    >
                        Xóa
                    </Button>
                </div>
            ),
        },
    ];

    const onChange = async (value) => {
        const response = await handleFetchApi(AEDUCATION.getClassesByCourseTeacher({ teacherId: value }));
        setTeacherId(value);
        setClasses(response?.data);
    };

    const onSearch = (value) => {
        console.log("search:", value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const addClassToTeacher = async (value) => {
        const response = await handleFetchApi(
            AEDUCATION.addClassToTeacher({
                courseId: course?.id,
                teacherId,
                data: {
                    class_ids: [value],
                },
            })
        );

        if (response?.status === "S0000") notification.success({ message: "Thành công!" });
    };

    const AddClass = ({ id }) => (
        <Select
            style={{ width: 300 }}
            showSearch
            placeholder="Select a class"
            optionFilterProp="children"
            onChange={addClassToTeacher}
            onSearch={onSearch}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={isLoadingClasses ? [] : classData?.data?.map((c) => ({ label: `${c.grade}${c.name} - ${c.school?.name}`, value: c.id }))}
        />
    );

    return (
        <Col span={18}>
            <div className="flex__between__center ">
                <Typography.Title level={4}>{course.name} </Typography.Title>
                <div className="flex__center__center gap-sm">
                    <Select
                        style={{ width: 200 }}
                        showSearch
                        placeholder="Select a teacher"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={course?.users?.map((u) => ({ label: u?.name, value: u?.id }))}
                    />

                    <Button onClick={showModal} type="primary" icon={<PlusOutlined />}></Button>
                </div>
            </div>
            <Modal title="Select a Teacher" open={isModalVisible} onCancel={handleCancel}>
                <Input size="large" onPressEnter={(e) => addTeacherToCourse.mutate(e.target?.value)} placeholder="Teacher's ID" />
            </Modal>
            {classes?.map(
                (cls) =>
                    cls?.course?.id === course?.id && (
                        <section>
                            <Divider />
                            <div>
                                <AddClass />
                            </div>
                            <Table dataSource={cls?.classes} columns={columns} rowKey="course_teacher_id" />
                        </section>
                    )
            )}
        </Col>
    );
};

export default CourseDetail;
