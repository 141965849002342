import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { StoreContext } from "../../../stores";
import { Segmented } from "antd";
import TeacherAssignment from "../components/TeacherAssignment";
import Loading from "../../../components/Loading";
import ListStudent from "../components/ListStudent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClassReport from "../../ClassReport";

const TeacherDashboard = React.memo(function TeacherDashboard() {
    const { courseId, classId } = useParams();
    const { selectedClass, setSelectedClass } = useContext(StoreContext);
    const [viewMode, setViewMode] = useState("assignment");
    const { t } = useTranslation();
    
    useEffect(() => {
        if (courseId && classId) setSelectedClass({ id: classId, courseId });
    }, [courseId, classId, setSelectedClass]);

    const renderViewMode = useMemo(() => {
        switch (viewMode) {
            case "assignment":
                return <TeacherAssignment />;
            case "list_student":
                return <ListStudent />;
            case "report":
                return <ClassReport />;
            default:
                return null;
        }
    }, [viewMode]);

    const handleViewModeChange = useCallback((value) => setViewMode(value), []);

    const segmentedOptions = [
        {
            label: t("tests_assignments"),
            value: "assignment",
        },
        {
            label: t("student_list"),
            value: "list_student",
        },
        {
            label: t("comments"),
            value: "report",
        },
    ];

    if (!selectedClass) return <Loading />;

    return (
        <>
            <Segmented onChange={handleViewModeChange} size="large" options={segmentedOptions} />
            {renderViewMode}
        </>
    );
});

export default TeacherDashboard;
