import React, { useContext } from "react";
import { Typography } from "antd";
import { fixedNumber } from "../../../utils/validation";
import moment from "moment";
import { t } from "i18next";
import { StoreContext } from "../../../stores";
import { ExerciseContext } from "../../../stores/exerciseStore";

const ScoreTicket = () => {
    const { me } = useContext(StoreContext);
    const { selectedExercise } = useContext(ExerciseContext);

    const { evaluation, startedAt, submittedAt, student, type } = selectedExercise;

    if (!submittedAt) return null;

    return (
        <section className="flex__center__stretch gap cb_exercise_mode__score_ticket">
            <div className="card cb_exercise_mode__score_ticket__score">
                <Typography.Text className="card__header">Điểm</Typography.Text>
                <h1 type="danger" className="score flex__center__center">
                    {fixedNumber(evaluation?.mark ?? 0)}
                </h1>
            </div>
            <div className="card cb_exercise_mode__score_ticket__info">
                <Typography.Text className="card__header" level={4}>
                    Họ Tên: <b>{student?.name}</b>
                    <span className="link">
                        Lớp:{" "}
                        <b>
                            {me?.grade}
                            {me?.className}
                        </b>
                    </span>
                </Typography.Text>
                <div className="flex__start__start flex__column">
                    <Typography.Text type="secondary">
                        Loại bài tập: &nbsp;
                        <b>{t(type)}</b>
                    </Typography.Text>
                    <Typography.Text type="secondary">
                        Thời gian làm bài: &nbsp;
                        <b>
                            {moment(startedAt).format("HH:mm")} - {moment(submittedAt).format("HH:mm")} ngày {moment(submittedAt).format("DD/MM/YYYY")}
                        </b>
                    </Typography.Text>
                    <Typography.Text type="secondary" italic>
                        Nhận xét: {evaluation?.comment || "Chưa có nhận xét"}
                    </Typography.Text>
                </div>
            </div>
        </section>
    );
};

export default ScoreTicket;
