import React, { useContext, useMemo } from "react";
import { Layout } from "antd";
import { StoreContext } from "../../stores";
import { UserEnums } from "../../configs/enums";
import TeacherDashboard from "./HomeByRole/TeacherDashboard";
import NoData from "../../components/NoData";
import StudentDashboard from "./HomeByRole/StudentDashboard";

const { Content } = Layout;

const Homepage = React.memo(function Homepage() {
    const { me } = useContext(StoreContext);
    const dashboardContent = useMemo(() => {
        if (!me) return null;
        switch (me?.role) {
            case UserEnums.TEACHER:
            case UserEnums.ADMIN:
            case UserEnums.HOME_TEACHER:
                return <TeacherDashboard />;
            case UserEnums.STUDENT:
                return <StudentDashboard />;
            default:
                return <NoData text="Xin lỗi, bạn không có quyền truy cập!" />;
        }
    }, [me]);

    return (
        <Content>
            <div id="cb_dashboard" className="page cb_dashboard">
                {dashboardContent}
            </div>
        </Content>
    );
});

export default Homepage;
