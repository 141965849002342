import React, { Fragment, lazy, useContext } from "react";
import { ExerciseContext } from "../../../stores/exerciseStore";
import { StoreContext } from "../../../stores";
import { ElementEnums, ExerciseModeEnums } from "../../../configs/enums";
import { Input, Typography, Divider, Radio, Space, Image, Tag } from "antd";
import { debounce, isNumeric } from "../../../utils";
import AudioPlayer from "./AudioPlayer";
import { getExerciseQuestionNumber, jumpToExercise } from "../utils";
const Choice = lazy(() => import("../elements/Choice"));
const Matching = lazy(() => import("../elements/Matching"));
const TextArea = lazy(() => import("../elements/TextArea"));
const TextInput = lazy(() => import("../elements/TextInput"));
const Voice = lazy(() => import("../elements/Voice"));
const MatchingLine = lazy(() => import("../elements/MatchingLine"));
const TickCross = lazy(() => import("../elements/TickCross"));
const Drag = lazy(() => import("../elements/Drag"));
const Drop = lazy(() => import("../elements/Drop"));

function Exercise(props) {
    const { exercise } = props;
    const { exerciseChildren } = useContext(StoreContext);
    const IMAGE = exercise?.content?.secondary_img_url || (exercise?.parent_item?.type === "Exam" && exercise?.parent_item?.content?.img_url);
    const AUDIO = exercise?.content?.audio_url || (exercise?.parent_item?.type === "Exam" && exercise?.parent_item?.content?.audio_url);
    const renderElementsContent = (element_type, element) => {
        switch (element_type) {
            case "fill":
                return <TextInput element={element} />;

            case "writing":
                return <TextArea element={element} />;

            case "tick_cross":
                return <TickCross element={element} />;

            case "choice":
            case "choice_many":
                return element.type === ElementEnums.CHOICE && <Choice element={element} />;

            case "matching":
                return <Matching elementType={isNumeric(element?.content?.v_string) ? "matching_item_list_1" : "matching_item_list_2"} element={element} />;

            case "drag_drop": {
                if (element?.type === "Drag") return <Drag element={element} />;
                if (element?.type === "Drop") return <Drop element={element} />;
                return;
            }

            default:
                return null;
        }
    };

    const renderExerciseImage = ({ element_type, childrens }) => {
        return childrens?.map((e) => {
            let children = exerciseChildren?.get(e);

            if (!children) return null;

            return (
                <>
                    {element_type === "voice" && <Voice element={children} />}
                    <div
                        key={children.id}
                        className="cb_exercise_mode__children"
                        id={children.id}
                        style={{
                            position: "absolute",
                            height: children.content.styles?.height + "%",
                            width: children.content.styles?.width + "%",
                            top: children.content.styles?.top + "%",
                            left: children.content.styles?.left + "%",
                        }}
                    >
                        {renderElementsContent(element_type, children)}
                    </div>
                </>
            );
        });
    };

    // const renderExerciseText = ({ element_type, exercise }) => {
    //     switch (element_type) {
    //         case "choice":
    //             const choiceList = exercise?.content?.v_choice_text?.choices;
    //             return (
    //                 <div className="exercise__text flex__center__center flex__column gap">
    //                     <Typography.Title level={3} type="danger">
    //                         {exercise?.name}
    //                     </Typography.Title>
    //                     <Divider />
    //                     <Typography.Title level={4}>{exercise?.content?.text_question_content}</Typography.Title>
    //                     <Radio.Group
    //                         buttonStyle="solid"
    //                         onChange={(e) => {
    //                             setChoice((prevState) => ({
    //                                 ...prevState,
    //                                 [exercise?.id]: choiceList[e.target.value?.split("_")[0]],
    //                             }));
    //                         }}
    //                     >
    //                         <Space direction="vertical">
    //                             {choiceList?.map((choice, index) => (
    //                                 <Radio.Button
    //                                     defaultChecked={choice[exercise?.id] === choice}
    //                                     className="exercise__text__button"
    //                                     value={`${index}_${exercise?.id}`}
    //                                     key={index}
    //                                 >
    //                                     {choice}
    //                                 </Radio.Button>
    //                             ))}
    //                         </Space>
    //                     </Radio.Group>
    //                 </div>
    //             );

    //         case "fill":
    //             const placeholder = "___";
    //             // Create a new input element
    //             const inputElement = (
    //                 <Input
    //                     onChange={debounce(
    //                         (e) =>
    //                             setFill((prev) => ({
    //                                 ...prev,
    //                                 [exercise?.id]: {
    //                                     value: e.target.value?.replace(/’/g, "'"),
    //                                 },
    //                             })),
    //                         500
    //                     )}
    //                     disabled={!(exerciseMode === ExerciseModeEnums.DOING)}
    //                     type="text"
    //                     placeholder={fill[exercise?.id]}
    //                     defaultValue={fill[exercise?.id]}
    //                 />
    //             );

    //             return (
    //                 <div>
    //                     <Typography.Title level={3} type="danger">
    //                         {exercise?.name}
    //                     </Typography.Title>
    //                     <Divider />
    //                     <div className="exercise__text flex__center__center gap">
    //                         {exercise?.content?.text_question_content.split(placeholder).map((part, index, array) => (
    //                             <>
    //                                 {part}
    //                                 {index < array.length - 1 && inputElement}
    //                             </>
    //                         ))}
    //                     </div>
    //                 </div>
    //             );

    //         case "matching":
    //             return (
    //                 <div className="exercise__text flex__center__center flex__column gap">
    //                     <Typography.Title level={3} type="danger">
    //                         {exercise?.name}
    //                     </Typography.Title>
    //                     <Divider />
    //                     <h5>{exercise?.content?.text_question_content}</h5>
    //                     <div className="exercise__text__body flex__around__center gap">
    //                         <div className="flex__center__center flex__column gap">
    //                             {exercise?.content?.v_matching_text?.matchingItemList1?.map((e, index) => (
    //                                 <MatchingText key={index} id={`m${index}_${exercise?.id}`} text={e} />
    //                             ))}
    //                         </div>
    //                         <div className=" flex__center__center flex__column gap">
    //                             {exercise?.content?.v_matching_text?.matchingItemList2?.map((e, index) => (
    //                                 <MatchingText key={index + 99} id={`c${index}_${exercise?.id}`} text={e} />
    //                             ))}
    //                         </div>
    //                     </div>
    //                 </div>
    //             );

    //         default:
    //             break;
    //     }
    // };

    // if (IMAGE && media?.children !== IMAGE)
    //     setMedia({
    //         children: IMAGE,
    //         type: "IMAGE",
    //     });

    if (!exercise) return;
    return (
        <section id={exercise.id} className={`cb_exercise_mode__section card --large flex__start__stretch flex__wrap`}>
            <Typography.Text className="card__header flex__between__center">
                <span>
                    {exercise?.name} <Tag color="#F9DCFB">{getExerciseQuestionNumber({ exercise })} câu hỏi</Tag>
                </span>
                {AUDIO && (
                    <div className="cb_exercise_mode__audio">
                        <AudioPlayer src={AUDIO} />
                    </div>
                )}
            </Typography.Text>

            {IMAGE && <Image loading="lazy" src={IMAGE} alt="ex_doc" />}
            <div
                style={{ width: IMAGE ? "50%" : "100%" }}
                className={`cb_exercise_mode__content flex__center__center ${exercise.content?.exercise_type === "voice" && "--has-voice"}`}
            >
                {exercise.content?.styles?.background && (
                    <img loading="lazy" className="cb_exercise_mode__image" src={exercise.content?.styles?.background} alt="ex_background" />
                )}
                {!exercise?.is_text_based &&
                    renderExerciseImage({
                        element_type: exercise.content?.exercise_type,
                        childrens: exercise.content?.children,
                    })}
                {/* {exercise?.is_text_based && renderExerciseText({ element_type: exercise.content?.exercise_type, exercise: exercise })} */}
                {exercise.content?.exercise_type === "matching" && <MatchingLine exercise={exercise} />}
            </div>
        </section>
    );
}

export default Exercise;
