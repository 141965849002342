import { Button, Typography, Popover, List } from "antd";
import React, { useContext } from "react";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { fixedNumber } from "../../../utils/validation";
import CustomTestIcon from "../../../components/Icons/CustomTestIcon";
import ButtonExit from "../../../components/ButtonExit";
import CustomHistoryIcon from "../../../components/Icons/CustomHistoryIcon";
import moment from "moment";
import CustomClockIcon from "../../../components/Icons/CustomClockIcon";
import { useNavigate } from "react-router-dom";
import { UserEnums } from "../../../configs/enums";
import { StoreContext } from "../../../stores";
import { AASSIGNMENT } from "../../../apis/exercise";
import SubHeader from "./SubHeader";

function Header({ assignment, gradingId }) {
    const navigate = useNavigate();
    const { me } = useContext(StoreContext);
    
    // const { data: gradingHistory = [], isLoading } = useQuery(
    //     ["gradingHistory", assignment?.id],
    //     () => handleFetchApi(AASSIGNMENT.getGradingByAssignment({ assignmentId: assignment?.id })),
    //     {
    //         enabled: !!assignment?.id,
    //         select: (response) => response.data || [],
    //     }
    // );

    // const gradingMenu = (
    //     <div className="loading-container">
    //         <List
    //             dataSource={gradingHistory}
    //             loading={isLoading}
    //             renderItem={(item) => (
    //                 <div
    //                     onClick={() => navigate(`/assignment/${assignment?.id}/grading/${item?.id}`)}
    //                     key={item.id}
    //                     className={`card --small --padding --hoverable flex__between__center gap-sm ${item?.id === gradingId && "--active"}`}
    //                 >
    //                     <Typography.Text className="flex__center__center gap-sm">
    //                         <CustomClockIcon /> {moment(item.startedAt).format("DD/MM/YYYY HH:mm")}
    //                     </Typography.Text>
    //                     <Typography.Text type="danger">
    //                         <b>{fixedNumber(item?.evaluation?.mark ?? 0)}</b> <small>/10</small>
    //                     </Typography.Text>
    //                 </div>
    //             )}
    //         />
    //     </div>
    // );

    return (
        <div className="cb_exercise_mode__header flex__between__center" id="cb_exercise_mode__header">
            <Typography.Title level={3} className="flex__start__center gap">
                <CustomTestIcon /> {assignment?.name}
            </Typography.Title>
            <div className="flex__center__center gap">
                {/* {assignment?.type !== "Exercise" && (
                    <Popover className="view_history" content={gradingMenu} trigger="click">
                        <Button icon={<CustomHistoryIcon />} type="text" size="large" loading={isLoading}>
                            {t("view_history")}
                        </Button>
                    </Popover>
                )} */}
                <SubHeader />
                <ButtonExit
                    size="large"
                    onExit={() => {
                        if (me?.role !== UserEnums.STUDENT) {
                            navigate(-1);
                        } else {
                            if (window.location.pathname?.includes("exercise")) {
                                navigate(-1);
                            } else navigate("/exercises");
                        }
                    }}
                    callback="/exercises"
                />
            </div>
        </div>
    );
}

export default Header;
