import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { handleFetchApi } from "../../../configs/apiRequest";
import { StoreContext } from "../../../stores";
import { Button, DatePicker, Input, Modal, notification, Table, Typography } from "antd";
import Loading from "../../../components/Loading";
import { debounce } from "../../../utils";
import CustomSearchIcon from "../../../components/Icons/CustomSearchIcon";
import NoData from "../../../components/NoData";
import DrawerStudentDetail from "./DrawerStudentDetail";
import { getMonthRange } from "../../../utils/datetime";
import moment from "moment";
import { fixedNumber } from "../../../utils/validation";
import { exportToCSV } from "../../../utils/export";
import CustomDownloadIcon from "../../../components/Icons/CustomDownloadIcon";
import CustomUploadIcon from "../../../components/Icons/CustomUploadIcon";
import { useTranslation } from "react-i18next";
import UploadFileScoreIelts from "./UploadFileScoreIelts";
import ResetPassword from "./ResetPassword";
import { AEDUCATION } from "../../../apis/education";
import { isAdmin } from "../../../configs/enums";
import { SelectOutlined, UserSwitchOutlined } from "@ant-design/icons";
import MoveStudents from "./MoveStudents";
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

function ListStudent() {
    const { t } = useTranslation();
    const { selectedClass, me } = useContext(StoreContext);
    const [filterdStudents, setFilterdStudents] = useState();
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectionMode, setSelectionMode] = useState(false);
    const [rowSelections, setRowSelections] = useState([]);
    const [timeRange, setTimeRange] = useState(getMonthRange());
    const [modal, setModal] = useState({
        open: false,
        type: null,
        data: null,
    });

    const {
        data: listStudents,
        isLoading,
        refetch,
    } = useQuery(
        [`${selectedClass?.id}_students_${timeRange.from}-${timeRange.to}`],
        async () => {
            const res = await handleFetchApi(AEDUCATION.getScoreByClass({ classId: selectedClass?.id, timeRange }));

            return res?.data;
        },
        {
            staleTime: 180000,
            cacheTime: 180000,
            refetchOnWindowFocus: false,
        }
    );
    const columns = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            render: (value, record, index) => index + 1,
        },
        {
            title: t("fullName"),
            dataIndex: "user_name",
            key: "user_name",
            render: (value, record) => (
                <div className="">
                    <Typography.Text className="--nowrap" strong>
                        {record?.student_name}
                    </Typography.Text>
                    <br />
                    <Typography.Text className="--nowrap" type="secondary">
                        {record?.user_name}
                    </Typography.Text>
                </div>
            ),
            sorter: (a, b) => a.student_name.localeCompare(b.student_name),
        },
        {
            title: t("writing"),
            dataIndex: "writing_score",
            key: "writing_score",
            render: (value) => fixedNumber(value),
            sorter: (a, b) => a.writing_score - b.writing_score,
        },
        {
            title: t("speaking"),
            dataIndex: "speaking_score",
            key: "speaking_score",
            render: (value) => fixedNumber(value),
            sorter: (a, b) => a.speaking_score - b.speaking_score,
        },
        {
            title: t("listening"),
            dataIndex: "listening_score",
            key: "listening_score",
            render: (value) => fixedNumber(value),
            sorter: (a, b) => a.listening_score - b.listening_score,
        },
        {
            title: t("reading"),
            dataIndex: "reading_score",
            key: "reading_score",
            render: (value) => fixedNumber(value),
            sorter: (a, b) => a.reading_score - b.reading_score,
        },
        {
            title: null,
            dataIndex: "actions",
            key: "actions",
            render: (value, record) => (
                <div>
                    <Button onClick={() => setSelectedStudent(record)} type="link">
                        {t("view")}
                    </Button>
                    <Button onClick={() => setModal((prev) => ({ ...prev, type: "RESET_PASSWORD", data: record }))} type="link" danger>
                        ĐỔI MK
                    </Button>
                </div>
            ),
        },
    ];

    const filterByNameOrUsername = (keyword) => {
        if (!keyword) {
            setFilterdStudents();
            return;
        }
        const results = listStudents?.filter((b) => {
            const username = b.user_name?.toLowerCase();
            const name = b.student_name?.toLowerCase();
            return username.includes(keyword) || name.includes(keyword);
        });
        if (results?.length === listStudents?.length) setFilterdStudents([]);
        else setFilterdStudents(results || []);
    };

    const disabledDate = (current) => {
        // Can not select days affter today and today
        return current > moment().endOf("day");
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setRowSelections(selectedRows);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.user_id,
        }),
    };

    return (
        <div className="loading_container">
            <Table
                rowSelection={
                    selectionMode && {
                        type: "checkbox",
                        ...rowSelection,
                    }
                }
                title={() => (
                    <div className="flex__between__center gap">
                        {selectionMode ? (
                            <Button
                                onClick={() => setModal({ open: true, type: "MOVE_STUDENT" })}
                                size="large"
                                type="primary"
                                icon={<UserSwitchOutlined />}
                                className="btn-success --md"
                            >
                                Chuyển {rowSelections?.length} học sinh
                            </Button>
                        ) : (
                            <Input
                                onChange={debounce((e) => filterByNameOrUsername(e.target.value?.toLowerCase()), 500)}
                                prefix={<CustomSearchIcon />}
                                placeholder={t("searchStudent")}
                                size="large"
                                className="ant-input-md --border-dark"
                            />
                        )}

                        <div className="flex__center__center gap">
                            <RangePicker
                                onChange={(date, dateStrings) => {
                                    if (!date) return;
                                    setTimeRange({
                                        from: date[0].format("YYYY-MM-DD"),
                                        to: date[1].format("YYYY-MM-DD"),
                                    });
                                }}
                                defaultValue={[
                                    moment(moment(timeRange.from).format("DD/MM/YYYY"), dateFormat),
                                    moment(moment(timeRange.to).format("DD/MM/YYYY"), dateFormat),
                                ]}
                                disabledDate={disabledDate}
                                format={dateFormat}
                            />
                            <Button className="btn-white" icon={<CustomDownloadIcon />} onClick={() => exportToCSV(listStudents, listStudents[0]?.user_name)}>
                                {/* {t("download")} */}
                            </Button>
                            <Button onClick={() => setModal({ open: true })} type="primary" icon={<CustomUploadIcon />}>
                                {/* {t("uploadScoreSheet")} */}
                            </Button>
                            {isAdmin(me) && (
                                <Button
                                    onClick={() => setSelectionMode(!selectionMode)}
                                    icon={<SelectOutlined />}
                                    type={selectionMode ? "primary" : "default"}
                                ></Button>
                            )}
                        </div>
                    </div>
                )}
                pagination={{ pageSize: 8 }}
                dataSource={filterdStudents ? filterdStudents : listStudents}
                columns={columns}
                rowKey={"user_id"}
                showSorterTooltip={false}
                locale={{ emptyText: <NoData imageWidth={70} text={t("noSearchResults")} /> }}
            />
            {isLoading && <Loading />}
            <DrawerStudentDetail student={selectedStudent} onClose={() => setSelectedStudent()} />
            <Modal
                open={modal?.open}
                width="clamp(350px, 85vw ,1280px)"
                onCancel={() => setModal({ open: false })}
                footer={false}
                title="Thêm bài TEST trên lớp"
            >
                <UploadFileScoreIelts
                    refetch={refetch}
                    setCreateIeltsTest={() => {}}
                    class_id={selectedClass?.id}
                    course_id={selectedClass?.courseId}
                    teacher_id={me?.id}
                />
            </Modal>
            <ResetPassword open={modal?.type === "RESET_PASSWORD"} close={() => setModal({ open: false })} student={modal?.data} />
            <MoveStudents open={modal?.type === "MOVE_STUDENT"} close={() => setModal({ open: false, type: null })} students={rowSelections} />
        </div>
    );
}

export default ListStudent;
