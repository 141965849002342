import axios from "axios";
import { ExerciseModeEnums } from "../../../configs/enums";
const apiKey = `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`;

export const saveChoice = ({ choice, setChoice, item }) => {
    let parent_id = item.parent_item?.id;
    let key = item.content.v_string?.slice(0, -1) + `_${parent_id}`;
    setChoice((prevState) => ({
        ...prevState,
        [key]: [
            {
                value: item.content.v_string?.slice(-1), // remove old value
                id: item.id,
                parent_id: parent_id,
            },
        ],
    }));
};

export const saveTickCross = ({ choice, setChoice, item, value }) => {
    let parent_id = item.parent_item?.id;
    let key = `${item?.name}_${parent_id}`;
    setChoice((prevState) => ({
        ...prevState,
        [key]: {
            value: value,
            id: item?.id,
        },
    }));
};

export const updateSelectedExercise = (data, fn) => {
    data?.data?.exerciseResult?.forEach((e) => {
        fn((prevState) => ({
            ...prevState,
            [e.item_id]: e,
        }));
    });
    fn((prevState) => ({
        ...prevState,
        isSubmitted: true,
        assignmentGrading: data?.data,
    }));
};

export const getElementStatus = (type, parentId, itemId, exerciseResult) => {
    switch (type) {
        case "choice":
            return exerciseResult[`${itemId}_${parentId}`];

        default:
            break;
    }
};

export const scaleUnit = {
    1: 1,
    0: 0.9,
    "-1": 0.8,
    "-2": 0.7,
    "-3": 0.6,
    "-4": 0.5,
    "-5": 0.4,
};

export const normalizeApostrophes = (text) => {
    if (!text) return text; // Trả về nếu chuỗi rỗng hoặc undefined
    const apostrophes = /['’‘ʻ´`]/g; // Regex bao gồm các dạng apostrophe
    return text.replace(apostrophes, "'");
};

export const getXYLine = (id1, id2, scale = 1) => {
    let div1 = document.getElementById(id1);
    let div2 = document.getElementById(id2);
    let x1 = div1?.offsetLeft + div1?.offsetWidth / 2;
    let y1 = div1?.offsetTop + div1?.offsetHeight / 2;
    let x2 = div2?.offsetLeft + div2?.offsetWidth / 2;
    let y2 = div2?.offsetTop + div2?.offsetHeight / 2;
    if (x2 - x1 > 150) {
        x1 += div1?.offsetWidth / 2;
        x2 -= div2?.offsetWidth / 2;
    }
    if (x1 - x2 > 150) {
        x1 -= div1?.offsetWidth / 2;
        x2 += div2?.offsetWidth / 2;
    }
    if (y2 - y1 > 150) {
        y1 += div1?.offsetHeight / 2 - 20;
        y2 -= div2?.offsetHeight / 2 - 20;
    }
    if (y1 - y2 > 150) {
        y1 -= div1?.offsetHeight / 2 - 20;
        y2 += div2?.offsetHeight / 2 - 20;
    }
    return {
        x1: x1 / scaleUnit[scale],
        y1: y1 / scaleUnit[scale],
        x2: x2 / scaleUnit[scale],
        y2: y2 / scaleUnit[scale],
        id: `${id1}_${id2}`,
    };
};

export const getObjByKey = (magic_key) => {
    let splitKey = magic_key?.split("_");
    return {
        label: splitKey[0],
        elementId: splitKey[1],
        parentId: splitKey[2],
    };
};

export const handleNextTab = (setActiveKey, setMedia, exerciseChildren, exerciseMode, id) => {
    setActiveKey(id);
    const currentExercise = exerciseChildren.get(id);
    const IMAGE = currentExercise?.parent_item?.content?.img_url || currentExercise?.content?.secondary_img_url;

    if (exerciseMode === ExerciseModeEnums.DOING) {
        setMedia((prevState) => ({
            ...prevState,
            current: id,
            autoplay: true,
            type: IMAGE ? "IMAGE" : "AUDIO",
            children: IMAGE,
        }));
    }
};

export const jumpToExercise = (exerciseId) => {
    const element = document.getElementById(exerciseId);

    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    } else {
        console.error(`Element with id ${exerciseId} not found.`);
    }
};
export const jumpToTop = (exerciseId) => {
    const element = document.getElementById(exerciseId);

    if (element) {
        element.scrollIntoView({ behavior: "smooth", top: -120 });
    } else {
        console.error(`Element with id ${exerciseId} not found.`);
    }
};


// export const jumpToDraff = (exerciseId) => {
//     const element = document.getElementById(exerciseId);

//     if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//     } else {
//         console.error(`Element with id ${exerciseId} not found.`);
//     }
// };

function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}

export function audioBufferToWav(audioBuffer) {
    const numOfChan = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChan * 2 + 44; // 2 bytes per sample + header
    const buffer = new ArrayBuffer(length);
    const view = new DataView(buffer);
    const sampleRate = audioBuffer.sampleRate;

    // Ghi header WAV
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChan * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true); // Chunk size
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numOfChan * 2, true); // Byte rate
    view.setUint16(32, numOfChan * 2, true); // Block align
    view.setUint16(34, 16, true); // Bits per sample
    writeString(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChan * 2, true);

    // Ghi dữ liệu âm thanh
    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
        for (let channel = 0; channel < numOfChan; channel++) {
            const sample = Math.max(-1, Math.min(1, audioBuffer.getChannelData(channel)[i]));
            view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
            offset += 2;
        }
    }

    return new Blob([buffer], { type: "audio/wav" });
}

export async function checkTopicValidity({ text, topic }) {
    const messages = [
        { role: "system", content: "You are a helpful assistant." },
        {
            role: "user",
            content: `
                Chủ đề: ${topic}
                Văn bản: ${text}
                Hãy xác định xem đoạn văn bản có liên quan đến chủ đề không. Trả về mức độ liên quan từ 1-10 (10 cao nhất).
            `,
        },
    ];

    const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
            model: "gpt-3.5-turbo",
            messages,
            max_tokens: 100,
            temperature: 0,
        },
        {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        }
    );

    return response.data.choices[0].message.content.trim();
}

export const playWord = (text) => {
    if (window.responsiveVoice) {
        window.responsiveVoice.speak(text, "US English Male", {
            volume: 1,
            rate: 1,
            pitch: 1,
        });
    } else {
        const speech = new SpeechSynthesisUtterance(text);
        speech.lang = "en-US";
        window.speechSynthesis.speak(speech);
    }
};

export const getExerciseQuestionNumber = ({ exercise = {} }) => {
    const { exercise_type, v_ids, v_connect, children } = exercise?.content;
    switch (exercise_type) {
        case "choice":
        case "tick_cross":
        case "choice_many":
            return v_ids?.length;

        case "fill":
        case "writing":
        case "voice":
            return children?.length;

        case "matching":
        case "drag_drop":
            return v_connect?.length;

        default:
            return 0;
    }
};
