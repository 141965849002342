import { Button, Modal, message, Typography } from "antd";
import React, { useContext, useEffect, useRef } from "react";
import { getDataToSubmit, validateDataToSubmit } from "../utils/submit";
import { ExerciseContext } from "../../../stores/exerciseStore";
import { StoreContext } from "../../../stores";
import { handleFetchApi } from "../../../configs/apiRequest";
import { fixedNumber } from "../../../utils/validation";
import { useTranslation } from "react-i18next";
import RatingByMark from "../../../components/RatingByMark";
import moment from "moment";
import { ExerciseModeEnums, isStudent } from "../../../configs/enums";
import CustomWarningIcon from "../../../components/Icons/CustomWarningIcon";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";
import Timeout from "./Timeout";
import { AASSIGNMENT } from "../../../apis/exercise";
import { jumpToTop } from "../utils";

const AUTO_SAVE_INTERVAL = 5000; // 5 seconds

const SubHeader = () => {
    const { t } = useTranslation();
    const lastDataRef = useRef([]); // Persist lastData across renders
    const { me, exerciseChildren, setLoading, loading } = useContext(StoreContext);
    const { selectedExercise, setSelectedExercise, choice, matched, fill, record, writing, dragList, setExerciseResult, isDoing, exerciseResult } =
        useContext(ExerciseContext);

    // Common data preparation
    const prepareSubmitData = () =>
        getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });

    // Shared modal success handler
    const showSuccessModal = ({ evaluation, startedAt, submittedAt, apiExerciseResult = [] }) => {
        const comment = evaluation?.comment;

        const firstExerciseId = selectedExercise?.assignment?.itemIds[0];
        jumpToTop(firstExerciseId);
        Modal.success({
            title: t("ex_submit_success"),
            okText: t("close"),
            width: "clamp(400px, 60dvw, 768px)",
            icon: <RatingByMark mark={evaluation?.mark} />,
            footer: null,
            className: `exercise-result --centered ${evaluation?.mark < 5 ? "low-score" : evaluation?.mark <= 8 ? "medium-score" : "high-score"}`,
            centered: true,
            content: (
                <div className="assignment-result">
                    {evaluation && (
                        <Typography.Title level={3} className="assignment-result__mark">
                            {fixedNumber(evaluation.mark)}
                            <small> / 10</small>
                        </Typography.Title>
                    )}
                    <Typography.Text className="text--left">
                        {t("comment")}: <b>{comment ?? t("no_data")}</b>
                    </Typography.Text>
                    <Typography.Text className="text--left">
                        {t("time_doing_assignment")}:{" "}
                        <b>
                            {moment(startedAt).format("HH:mm")} - {moment(submittedAt).format("HH:mm")} {moment(submittedAt).format("DD/MM/YYYY")}
                        </b>
                    </Typography.Text>
                    {!evaluation && (
                        <Typography.Text type="danger" style={{ textAlign: "center" }}>
                            {t("ex_pending_evaluation")}
                        </Typography.Text>
                    )}
                </div>
            ),
        });

        if (apiExerciseResult) {
            setExerciseResult(new Map(apiExerciseResult?.map((result) => [result.item_id, result])));
        }
        setSelectedExercise((prev) => ({
            ...prev,
            evaluation,
            exerciseMode: ExerciseModeEnums.REVIEW,
            submittedAt,
            startedAt,
        }));
    };

    // Submit Assignment
    const submitAssignment = async () => {
        const dataToSubmit = prepareSubmitData();
        setLoading(true);
        message.loading(t("ex_calculating"));

        const result = await handleFetchApi(AASSIGNMENT.submit({ gradingId: selectedExercise?.id, data: dataToSubmit }));
        setLoading(false);

        if (result?.status !== "S0000") {
            Modal.error({ title: t("ex_submit_err"), content: result?.message || result?.data?.message });
            return;
        }

        const { exerciseResult: apiExerciseResult, evaluation, startedAt, submittedAt } = result.data;
        showSuccessModal({ evaluation, startedAt, submittedAt, apiExerciseResult });
    };

    // Submit Exercise
    const submitExercise = async () => {
        const dataToSubmit = prepareSubmitData();
        setLoading(true);
        message.loading(t("ex_calculating"));

        const result = await handleFetchApi(AASSIGNMENT.submitExercise({ data: dataToSubmit[0] }));
        setLoading(false);

        if (result?.status === "AS0006") {
            Modal.error({ title: t("ex_submit_err"), content: t("ex_submit_err_content") });
            return;
        }
        if (result?.status !== "S0000") {
            Modal.error({ title: t("ex_submit_err"), content: result?.message || result?.data?.message });
            return;
        }

        const { evaluation, startedAt, submittedAt } = result.data;
        showSuccessModal({ evaluation, startedAt, submittedAt, apiExerciseResult: [result.data] })
    };

    // Auto-save logic
    const autoSave = async () => {
        const dataToSave = prepareSubmitData();
        if (JSON.stringify(dataToSave) === JSON.stringify(lastDataRef.current)) return;

        lastDataRef.current = dataToSave;
        await handleFetchApi(AASSIGNMENT.save({ gradingId: selectedExercise?.id, data: dataToSave }));
    };

    const checkNeedAutoSave = () => selectedExercise?.type !== "exercise" && isDoing() && isStudent(me) && !selectedExercise?.submittedAt;

    useEffect(() => {
        if (!checkNeedAutoSave()) return;

        const interval = setInterval(autoSave, AUTO_SAVE_INTERVAL);
        return () => clearInterval(interval);
    }, [selectedExercise, me, choice, matched, fill, record, writing, dragList, exerciseChildren, exerciseResult]);

    // Handle submit with validation
    const handleSubmit = () => {
        const dataToSubmit = prepareSubmitData();
        const isValid = validateDataToSubmit({ dataToSubmit, exerciseChildren });
        const submitFn = selectedExercise?.type === "exercise" ? submitExercise : submitAssignment;
        const modalProps = isValid
            ? {
                  title: t("ex_confirm_submit"),
                  content: t("ex_submit_wish"),
                  okText: t("submit"),
                  cancelText: t("back"),
                  icon: <CustomWarningIcon />,
                  onOk: () => {
                      submitFn();
                  },
              }
            : {
                  title: t("ex_incomplete_title"),
                  content: t("ex_incomplete_warning"),
                  okText: t("submit_anyway"),
                  cancelText: t("back"),
                  icon: <CustomDangerIcon />,
                  okType: "danger",
                  onOk: () => {
                      submitFn();
                  },
              };

        Modal.confirm({ ...modalProps, centered: true });
    };

    // Early return if not applicable
    if (selectedExercise?.submittedAt || !isDoing()) return null;

    return (
        <div className="flex__between__center gap exercise_subheader">
            <Timeout onSubmit={submitAssignment} />
            <Button type="primary" size="large" onClick={handleSubmit} loading={loading}>
                {t("submit")}
            </Button>
        </div>
    );
};

export default SubHeader;
