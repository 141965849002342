import React, { memo, useCallback, useContext, useMemo } from "react";
import { StoreContext } from "../../stores";
import { ExerciseContext } from "../../stores/exerciseStore";
import { Layout } from "antd";
import { AssignmentTypeEnums, ExerciseModeEnums } from "../../configs/enums";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { cacheQueryConfig, handleFetchApi } from "../../configs/apiRequest";
import Loading from "../../components/Loading";
import { fetchExercises } from "./utils/fetch";
import NoData from "../../components/NoData";
import ErrorPage from "../Error";
import { DndProvider } from "react-dnd";
import Exercise from "./components/Exercise";
import Header from "./components/Header";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AASSIGNMENT } from "../../apis/exercise";
import ScoreTicket from "./components/ScoreTicket";

function ExerciseMode() {
    const { exercise_id, grading_id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const type = pathname?.split("/")[1];

    const { exerciseChildren, setExerciseChildren } = useContext(StoreContext);
    const { setChoice, setFill, setMatched, setExerciseResult, setSelectedExercise } = useContext(ExerciseContext);

    const handleUpdateExerciseResult = ({ exerciseResult }) => {
        if (!exerciseResult?.length) return;
        exerciseResult.forEach((result) => {
            const { content, item_id } = result;
            const itemMapped = exerciseChildren.get(item_id);
            if (!itemMapped) {
                return;
            }
            switch (itemMapped?.content?.exercise_type) {
                case "choice":
                case "choice_many":
                    const choiceMappedByResult = {};
                    if (content?.choice?.length > 0) {
                        content.choice.forEach((item) => {
                            const itemM = exerciseChildren.get(item);
                            const key = itemM?.content.v_string?.slice(0, -1);
                            choiceMappedByResult[`${key}_${item_id}`] = [
                                {
                                    id: item,
                                    parent_id: item_id,
                                    value: key,
                                },
                            ];
                        });
                        setChoice((prev) => ({ ...prev, ...choiceMappedByResult }));
                    }
                    break;
                case "fill":
                case "tick_cross":
                    setFill((prev) => ({ ...prev, ...content[itemMapped?.content?.exercise_type] }));
                    break;
                case "writing":
                    setFill((prev) => ({ ...prev, ...content.writing }));
                    break;
                case "matching":
                    setMatched((prev) => ({
                        ...prev,
                        [item_id]: content?.matching,
                    }));
                    break;
                default:
                    break;
            }
        });
    };

    const fetchData = async () => {
        if (type === "assignment") {
            const response = await handleFetchApi(AASSIGNMENT.getGradingDetail({ assignmentId: exercise_id, gradingId: grading_id }));
            const { assignment, assignment_grading } = response.data;
            const { exerciseResult = [] } = assignment_grading;
            await fetchExercises({
                exercisesIds: assignment.itemIds,
                setExerciseChildren,
                exerciseChildren,
            });

            const exerciseResultGroupById = new Map(assignment_grading.exerciseResult.map((result) => [result.item_id, result]));
            setExerciseResult(exerciseResultGroupById);
            const isReview = assignment_grading && assignment_grading?.submittedAt;
            const results = { ...assignment_grading, type: assignment?.type, exerciseMode: isReview ? ExerciseModeEnums.REVIEW : ExerciseModeEnums.DOING };
            if (!isReview) {
                handleUpdateExerciseResult({ exerciseResult });
            }
            setSelectedExercise(results);
            return results;
        } else {
            const exercises = await fetchExercises({
                exercisesIds: [exercise_id],
                setExerciseChildren,
                exerciseChildren,
            });
            const thisExercise = {
                children: exercises,
                type: type,
                exerciseMode: ExerciseModeEnums.DOING,
                submittedAt: null,
            };
            setSelectedExercise(thisExercise);
            return thisExercise;
        }
    };

    const { data: exercise, isLoading, isFetching, isError } = useQuery([`${exercise_id}/${grading_id}`], fetchData, cacheQueryConfig({ time: 0 }));

    const renderExerciseContent = () => {
        if (type === "assignment") {
            if (exercise?.assignment?.type === AssignmentTypeEnums.OFFLINE) {
                return <NoData text="Bài TEST trên lớp sẽ không có nội dung! Hãy chọn 1 học sinh để cập nhật điểm." />;
            }
            return (
                <>
                    {exercise?.assignment?.itemIds?.map((child) => {
                        const itemMapped = exerciseChildren.get(child);
                        if (!itemMapped) {
                            console.error(`Item with ID ${child} not found in exerciseChildren.`);
                            return null;
                        }
                        return <Exercise key={itemMapped?.id} id={itemMapped?.id} textBased={itemMapped?.is_text_based} exercise={itemMapped} />;
                    })}
                </>
            );
        }

        return (
            exercise?.children?.[0] && (
                <>
                    <Exercise exercise={exercise.children[0]} />
                </>
            )
        );
    };

    if (isError) return <ErrorPage />;
    return (
        <Layout className="cb_exercise_mode" id="cb_exercise_mode">
            <Header gradingId={exercise?.id} assignment={exercise?.assignment || exercise?.children[0]} />
            <Layout>
                {/* <Controls grading={exercise} type={type} /> */}
                <DndProvider backend={HTML5Backend}>
                    <div className="cb_exercise_mode__body" id="cb_exercise_mode__body">
                        <ScoreTicket grading={exercise} />
                        {renderExerciseContent()}
                    </div>
                </DndProvider>
            </Layout>
            {(isLoading || isFetching) && <Loading />}
        </Layout>
    );
}

export default ExerciseMode;
