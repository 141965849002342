import React, { useContext, useState } from "react";
import { Table, Button, notification, Layout, Typography, Space, Modal, Segmented, Input, DatePicker } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { cacheQueryConfig, handleFetchApi, validResponse } from "../../../configs/apiRequest";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Loading from "../../../components/Loading";
import Report from "../../../components/Report"; // Import the Report component
import CustomTrashIcon from "../../../components/Icons/CustomTrashIcon";
import { SearchOutlined } from "@ant-design/icons";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";
import { AREPORT } from "../../../apis/report";
import { UserEnums } from "../../../configs/enums";
import { StoreContext } from "../../../stores";
import { DELETE, GetReport } from "../fetch";
import { groupByCustomKey } from "../../../utils";

const { Content } = Layout;
const TODAY = moment().format("YYYY-MM-DD");

const AdminReport = () => {
    const { t } = useTranslation();
    const {
        me: { role },
    } = useContext(StoreContext);
    const [modal, setModal] = useState({ open: false, reportData: null, adminComment: null, type: null });
    const [viewMode, setViewMode] = useState("UNCONFIRMED");
    const [date, setDate] = useState(TODAY);

    const closeModal = () => setModal((prev) => ({ ...prev, open: false, type: null }));

    const handleComment = (comment) => {
        setModal((prev) => ({ ...prev, adminComment: comment }));
    };

    const { data, refetch, isLoading } = useQuery(
        ["fetchReports", date],
        async () => {
            let response = await GetReport({ role, date });
            // Sort the data by created_at
            const sortedData = response?.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const groupedData = await groupByCustomKey({ array: sortedData, logical: (current) => (current?.is_confirmed ? "CONFIRMED" : "UNCONFIRMED") });
            return groupedData;
        },
        {
            ...cacheQueryConfig({ time: 3 }),
            enabled: !!date,
        }
    );

    const deleteReport = async (reportId) => {
        const response = await DELETE({ role, reportId });
        if (validResponse({ response })) {
            notification.success({ message: "Report deleted successfully!" });
            refetch();
        } else notification.error({ message: "Failed to delete the report." });
    };

    const columns = [
        {
            title: t("date"),
            dataIndex: "date",
            key: "date",
            render: (date, record) => moment(record?.created_at).format("DD/MM/YYYY"),
        },
        {
            title: t("content"),
            dataIndex: "content",
            key: "content",
            render: (text) => (text ? `${text.substring(0, 30)}...` : ""),
        },
        {
            title: t("discipline_status"),
            dataIndex: ["class_report_status", "discipline"],
            key: "discipline",
            render: (value) => t(value),
        },
        {
            title: t("study_status"),
            dataIndex: ["class_report_status", "study"],
            key: "study",
            render: (value) => t(value),
        },
        {
            title: t("teacher"),
            dataIndex: ["teacher", "name"],
            key: "teacher_name",
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search class"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value, record) => record.teacher.name.toString().toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: t("class"),
            dataIndex: "school_class",
            key: "class_name",
            render: (value, record) => `Lớp ${value?.grade} ${value?.name?.replace("Lớp ", "")}`,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search class"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}></Button>
                        <Button icon={<CustomTrashIcon />} onClick={() => clearFilters()} size="small" style={{ width: 90 }}></Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value, record) => {
                const label = `Lớp ${record?.school_class?.grade} ${record?.school_class?.name?.replace("Lớp ", "")}`;
                return label.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: t("status"),
            dataIndex: "is_confirmed",
            key: "is_confirmed",
            render: (value) => <Typography.Text type={value ? "success" : "warning"}>{value ? "Đã xác nhận" : "Chờ xác nhận"}</Typography.Text>,
        },
        {
            title: "",
            key: "actions",
            render: (_, record) => (
                <Space>
                    {
                        <>
                            <Button size="small" type="link" onClick={() => setModal({ open: true, reportData: record, type: "VIEW" })}>
                                XEM
                            </Button>

                            <Button
                                size="small"
                                icon={<CustomTrashIcon />}
                                type="text"
                                danger
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Bạn có thực sự muốn xóa báo cáo này?",
                                        centered: true,
                                        icon: <CustomDangerIcon />,
                                        onOk: () => deleteReport(record.id),
                                    });
                                }}
                            >
                                {t("delete")}
                            </Button>
                        </>
                    }
                </Space>
            ),
        },
    ];

    return (
        <Content>
            <div className="page admin_report">
                <Typography.Title level={4} className="flex__between__center">
                    Báo cáo hàng ngày{" "}
                    <div className="flex__center__center gap">
                        <Typography.Text>Ngày</Typography.Text>
                        <DatePicker
                            onChange={(e, dateString) => {
                                setDate(dateString);
                            }}
                            size="large"
                            defaultValue={moment()}
                        />
                        <Segmented
                            onChange={(value) => setViewMode(value)}
                            size="large"
                            options={[
                                {
                                    label: "Chưa duyệt",
                                    value: "UNCONFIRMED",
                                },
                                {
                                    label: "Đã duyệt",
                                    value: "CONFIRMED",
                                },
                            ]}
                        />
                    </div>
                </Typography.Title>
                {isLoading ? <Loading /> : <Table dataSource={data && data[viewMode]} columns={columns} rowKey="id" />}

                <Modal
                    centered={true}
                    title="Chi tiết báo cáo"
                    open={modal.type === "VIEW"}
                    onCancel={() => setModal({ open: false, reportData: null })}
                    footer={null}
                    width="90dvw"
                >
                    {modal.reportData && (
                        <Report close={closeModal} footer={true} mode="VIEW" onComment={handleComment} initData={modal.reportData} refetch={refetch} />
                    )}
                </Modal>
            </div>
        </Content>
    );
};

export default AdminReport;
