import { Button, Col, Input, List, Modal, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import CustomSearchIcon from "../../../components/Icons/CustomSearchIcon";
import { debounce } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi, validResponse } from "../../../configs/apiRequest";
import { AEDUCATION } from "../../../apis/education";
import { useParams } from "react-router-dom";
import CustomSuccessIcon from "../../../components/Icons/CustomSuccessIcon";
import { t } from "i18next";

const MoveStudents = ({ students, open, close }) => {
    const { courseId, classId } = useParams();
    const [selectedClass, setSelectedClass] = useState();

    const { data: classData, isLoading: isLoadingClasses } = useQuery(
        ["classes"],
        async () => {
            const response = await handleFetchApi(AEDUCATION.getClass());
            return response?.data;
        },
        {
            enabled: !!courseId,
        }
    );

    const handerTransferStudents = async () => {
        const response = await handleFetchApi(
            AEDUCATION.transferStudents({ oldClassId: classId, newClassId: selectedClass, studentIds: students?.map((e) => e.user_id) })
        );
        if (validResponse({ response })) {
            close();
            Modal.success({
                icon: <CustomSuccessIcon />,
                centered: true,
                title: t("success"),
                content: "Chuyển học sinh thành công",
            });
        }
    };

    return (
        <Modal open={open} onCancel={close} width="clamp(350px, 85vw ,1280px)" title="Danh sách học sinh chuyển đi" footer={false}>
            <Row gutter={[12, 12]}>
                {students?.map((student, index) => (
                    <Col span={8}>
                        <Typography.Text strong>
                            {index + 1}. {student.student_name}
                        </Typography.Text>
                        <br />
                        <Typography.Text type="secondary">ID: {student.user_name}</Typography.Text>
                    </Col>
                ))}
            </Row>
            <br />
            <Typography.Title level={5}>Chọn lớp cần chuyển và Xác nhận</Typography.Title>
            <div className="flex__start__center gap">
                {classData?.length && (
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Chọn lớp cần chuyển"
                        size="large"
                        showSearch
                        onChange={(e) => setSelectedClass(e)}
                        onSearch={() => {}}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={classData?.map((cls) => ({ label: `Lớp ${cls.grade} ${cls.name} - ${cls?.school?.name}`, value: cls?.id }))}
                    />
                )}
                <Button type="primary" size="large" onClick={handerTransferStudents}>
                    XÁC NHẬN
                </Button>
            </div>
            <br />
        </Modal>
    );
};

export default MoveStudents;
